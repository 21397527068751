import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getH5pNameByH5pType'
})
export class getH5pNameByH5pTypePipe implements PipeTransform {

  transform(value: any): any {

    switch (value) {

        case 'FC':

            return '(Flash Cards)'
 
        case 'SCS':

            return '(Single Choice Set)'

        case 'DTW':
            
            return '(Drag The Word)'
 
        default:
            return '';
    }
  }
}
