import {Pipe, PipeTransform, Injectable} from '@angular/core';
import * as moment from 'moment';
@Pipe({
    name: 'timeFilter'
})
@Injectable()
export class timeFilterPipe implements PipeTransform {
    transform(time: any, field: string, value: string): any {
       
        if(!time || time == 0){

            return 0
        }
        time = Number(time);
        if(time < 60){

            return 0
        }
        const totalMinutes = Math.floor(time / 60);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours}h ${minutes}m`;
    }
}