<mat-card id='view-programmes'>
    <mat-card-header>
        <div>
            <mat-icon class="v-align-middle"> school </mat-icon>
            <span class="v-align-middle"> Learners </span>
        </div>

        <span class="spacer"></span>
        <span>
            <button id="add_learner_button" mat-stroked-button class="right" color="primary"
                [routerLink]="['./new-learner']">Add learner</button>
        </span>
    </mat-card-header>
    <mat-card-content class="learners">
        <mat-form-field id="search_users_form" class="search_users_form" appearance="fill">
            <mat-label>Search learner</mat-label>
            <input autocomplete="off" id="search_user_input" matInput #search_user_input aria-label="Search for a user"
                (keydown.enter)="onFilter(search_user_input.value)">
            <button id="on_search_button" mat-button matSuffix mat-icon-button aria-label="Search"
                (click)="onFilter(search_user_input.value)">
                <mat-icon>search</mat-icon>
            </button>
            <button id="clear_search_button" *ngIf="search_user_input.value !==''" mat-button matSuffix mat-icon-button
                aria-label="Clear" (click)="onFilter(''); search_user_input.value=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-table id="learners_table" #table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="fullName">
                <mat-header-cell *matHeaderCellDef id="fullName" mat-sort-header>
                    Name
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.fullName }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="NI_Number">
                <mat-header-cell *matHeaderCellDef id="NI_Number">
                    NI number
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.niNumber }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="DateofBirth">
                <mat-header-cell *matHeaderCellDef id="DateofBirth">
                    Date of birth
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.dateOfBirth | date:'dd MMM yyyy'}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                <mat-cell *matCellDef="let element">

                    <button id="view_user_button_{{element.id}}" title="View" mat-icon-button color="primary"
                        attr.aria-label="View" matTooltip="View" [matTooltipShowDelay]="800"
                        [routerLink]="['view-learner']" [queryParams]="{ id: element.id ,name:element.fullName,operation:'view' }">
                        <mat-icon>visibility</mat-icon>
                    </button>
                    <button id="edit_user_button_{{element.id}}" mat-icon-button color="primary" title="Edit"
                        [routerLink]="['./edit-learner']" [queryParams]="{ id: element.id ,name:element.fullName,operation:'edit' }" attr.aria-label="Edit" matTooltip="Edit"
                        [matTooltipShowDelay]="800">
                        <mat-icon>create</mat-icon>
                    </button>
                    <button id="delete_user_button" mat-icon-button attr.aria-label="Delete" matTooltip="Delete"
                        [matTooltipShowDelay]="800" (click)="onDelete(element.id)">
                        <mat-icon color="accent">delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator id="user_pagination" [pageSize]="pageSize" [showFirstLastButtons]='true' [hidePageSize]="true">
        </mat-paginator>
    </mat-card-content>
</mat-card>
