<mat-card id="view-participant-asc">
    <mat-card-header>
        <span class="search-container">
            <mat-form-field id="search_service_user_form" class="search_by_lr">
                <mat-label></mat-label>
                <input id="search_service_user_input" autocomplete="off"
                    (keydown.enter)="onFilter(search_service_user_input.value)" matInput #search_service_user_input
                    aria-label="Search a user">
                <button id="do_search_button" mat-button matSuffix mat-icon-button aria-label="Search"
                    (click)="onFilter(search_service_user_input.value)">
                    <mat-icon>search</mat-icon>
                </button>
                <button id="clear_search_button" *ngIf="search_service_user_input.value !==''" mat-button matSuffix
                    mat-icon-button aria-label="Clear" (click)="onFilter(''); search_service_user_input.value=''">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </span>

        <span class="search-container mat-select-arrow ">
            <mat-form-field class="search_by_lr">
                <mat-select  (selectionChange)="findWorker($event)" [(ngModel)]="select">
                    <mat-option value="All Service Users">All Service Users</mat-option>
                    <mat-option value="My Service Users">My Service Users</mat-option>
                </mat-select>
            </mat-form-field>
        </span>
        <span class="spacer1"></span>
        <span class="spacer"></span>
            <button *auth="[177,1]" id="add_learner_button" mat-stroked-button class="right" color="primary"
                (click)="onClickAddParticipant()">Add student</button>
       
    </mat-card-header>
    <mat-card-content class="students">
        <mat-table id="student_table" #table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="fullName">
                <mat-header-cell *matHeaderCellDef id="fullName" mat-sort-header>
                    Name
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.fullName }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="DateofBirth">
                <mat-header-cell *matHeaderCellDef id="DateofBirth">
                    Date of birth
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.dateOfBirth | customMonthFormat}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Username">
                <mat-header-cell *matHeaderCellDef id="Username">
                    Username
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.userName }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Worker">
                <mat-header-cell *matHeaderCellDef id="Worker">
                    Worker
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.workerName }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                <mat-cell *matCellDef="let element">

                    <button  *auth="[177,2]" id="view_user_button_{{element.id}}" mat-icon-button color="primary" matTooltip="View"
                    [matTooltipShowDelay]="800" [routerLink]="['./participant-professional-view']"
                    [queryParams]="{ id: element.id ,name:element.fullName,operation:'view' }">
                    <mat-icon>visibility</mat-icon>
                </button>
                    <button *auth="[177,3]" id="edit_user_button_{{element.id}}" mat-icon-button color="primary"
                        [routerLink]="['./edit-participant']"
                        [queryParams]="{ id: element.id ,name:element.fullName,operation:'edit' }" matTooltip="Edit"
                        [matTooltipShowDelay]="800">
                        <mat-icon>create</mat-icon>
                    </button>
                    <button *auth="[177,4]" id="delete_user_button" mat-icon-button attr.aria-label="Delete"
                        matTooltip="Delete" (click)="onDeleteClicked(element.id)" [matTooltipShowDelay]="800">
                        <mat-icon color="accent">delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator id="user_pagination" [pageSize]="pageSize" [showFirstLastButtons]='true'
            (page)="pageEvents($event)" [hidePageSize]="true" [(pageIndex)]="index">

        </mat-paginator>
    </mat-card-content>
</mat-card>