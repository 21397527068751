import { NgModule } from '@angular/core';
import { FilterPipe } from './filter.pipe';
import { SafePipe } from './safe.pipe';
import { TruncatePipe } from './truncate.pipe';
import { GetLabelPipe } from './get-label.pipe';
import { DisableFilterPipe } from './disable-filter.pipe';
import { StatusFilterPipe } from './statusFilter.pipe';
import { OperationNamePipe } from './operationName';
import { CustomMonthFormatPipe } from './custom-month-format-pipe';
import { getH5pNameByH5pTypePipe } from './getH5pName.pipe';
import { RemoveKeywordPipe } from './remove-keyword.pipe';
import { timeFilterPipe } from './timeFilter.pipe';

@NgModule({
  declarations: [FilterPipe, SafePipe, TruncatePipe, GetLabelPipe, DisableFilterPipe, StatusFilterPipe, OperationNamePipe,CustomMonthFormatPipe,getH5pNameByH5pTypePipe,timeFilterPipe, RemoveKeywordPipe],
  exports: [FilterPipe, SafePipe, TruncatePipe, GetLabelPipe, DisableFilterPipe, StatusFilterPipe, OperationNamePipe,CustomMonthFormatPipe,getH5pNameByH5pTypePipe,timeFilterPipe, RemoveKeywordPipe]
})

export class FilterPipeModule { }
