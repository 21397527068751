import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeKeyword'
})
export class RemoveKeywordPipe implements PipeTransform {

  transform(value: string, keyword: string): string {

    return value ? value.replace(keyword, '') : value;
  }
}
