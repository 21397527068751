import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppConfirmService } from 'src/app/framework/components/app-confirm/app-confirm.service';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { SessionsService } from 'src/app/sessions/sessions.service';
import { ILearners } from '../learners/learners.interface';
import { ParticipantAscService } from './participant-asc.service';

@Component({
  selector: 'app-participant-asc',
  templateUrl: './participant-asc.component.html',
  styleUrls: ['./participant-asc.component.scss']
})
export class ParticipantAscComponent implements OnInit {


  dataSource: any = new MatTableDataSource<ILearners>();
  displayedColumns: string[] = ['fullName', 'DateofBirth', 'Username', 'Worker', 'actions'];
  filterBy = { 'keyword': '', 'refUserType': 'SU' };
  sortColumn = 'fullName';
  sortDirection = 'asc';
  select = 'All Service Users';
  showFilteredList: boolean = false;
  allServiceUserList: boolean = true;
  showcaseLoad: boolean = false;
  filteredListSize: any;
  pageSize = 10;
  index: number = 0;


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  userType: number = 3;

  constructor(
    private readonly snackBarService: SnackBarService,
    private readonly participantAscService: ParticipantAscService,
    private readonly router: Router,
    private readonly appConfirmService: AppConfirmService,
    private readonly sessionService: SessionsService,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.resolveUsers(this.filterBy);
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(data => {
      this.sortColumn = data.active;
      this.sortDirection = data.direction;
      this.paginator.pageIndex = 0;
      if (this.allServiceUserList) {
        this.resolveUsers(this.filterBy);
        this.showcaseLoad = false;
      } else {
        this.resolveFilteredServiceUsers(this.filterBy);
      }
    });

    this.paginator.page
      .pipe(
        tap(() => {
          if (this.allServiceUserList) {
            this.resolveUsers(this.filterBy);
          } else {
            this.resolveFilteredServiceUsers(this.filterBy);
          }
          //   document.querySelector('#student').scrollIntoView();
        }
        )
      )
      .subscribe();
  }


  onFilter(filterString: string) {
    this.allServiceUserList = true;
    this.showFilteredList = false;
    this.select = 'All Service Users';
    this.showcaseLoad = false;
    this.filterBy.keyword = filterString;
    this.paginator.pageIndex = 0;
    this.resolveUsers(this.filterBy);
  }
  resolveUsers(filterBy) {
    this.allServiceUserList = true;
    this.showFilteredList = false;
    let currentPageIndex = 0;
    if (!this.paginator) {
      this.route.queryParams.subscribe(
        (queryParams: Params) => {

          this.index = queryParams.page;
          if (queryParams.direction != undefined) {
            this.sortDirection = queryParams.direction;
          }
          if (queryParams.sort != undefined) {
            this.sortColumn = queryParams.sort;
          }
        }
      )
      if (this.index == undefined)
        currentPageIndex = 0;
      else
        currentPageIndex = this.index;

    } else {
      currentPageIndex = this.paginator.pageIndex;
    }

    this.participantAscService.getServiceUsers(`${this.sortColumn},${this.sortDirection}`, this.pageSize, currentPageIndex, filterBy)
      .subscribe(data => {
        data.content.forEach(element => {
          element.fullName = element.fullName;
        });
        data.content.forEach(element => {
          if (element.workerName === null) {
            element.workerfirstname = element.workerName;
          }
          else {
            element.workerfirstname = element.workerName.split(' ')[0];
          }
        }
        );
        this.dataSource.data = data.content;
        this.paginator.length = data.totalElements;
      },
        error => {
          this.snackBarService.error(`${error.error.applicationMessage}`);
          this.router.navigate(['./student']);
        }
      );
  }

  findWorker(event) {
    if (event.value === 'My Service Users') {
      this.allServiceUserList = false;
      this.showFilteredList = true;
      this.showcaseLoad = true;
      this.paginator.pageIndex = 0;
      this.resolveFilteredServiceUsers(this.filterBy);

    } else {
      this.allServiceUserList = true;
      this.showcaseLoad = false;
      this.paginator.pageIndex = 0;
      this.resolveUsers(this.filterBy);
    }
  }

  resolveFilteredServiceUsers(filterBy) {
    let currentPageIndex = 0;
    if (!this.paginator) {
      currentPageIndex = 0;
    } else {
      currentPageIndex = this.paginator.pageIndex;
    }
    this.participantAscService.getFilteredSUList(`${this.sortColumn},${this.sortDirection}`, this.pageSize, currentPageIndex, filterBy).subscribe(data => {
      data.content.forEach(element => {
        element.workerfirstname = element.workerName.split(' ')[0];
      });
      this.dataSource.data = data.content;
      this.paginator.length = data.totalElements;
      this.filteredListSize = data.totalElements;
    })
  }

  onClickAddParticipant() {

    this.participantAscService.getValidLicences(this.userType).subscribe(data => {
      if (data.isAuthorisedLicence) {
        this.router.navigateByUrl('/student/new-participant');
      }
      else {
        this.notAddPopup();
      }

    })
  }

  notAddPopup() {
    const dialogRef = this.appConfirmService.confirm({
      title: `Licences`,
      message: `It appears you have exceeded your user limit for this account. 
      Please contact us on support.mailbox@meganexus.com or 0207 843 4343 `,
      showOkButtonOnly: true,
      padding: '22px'
    });
    dialogRef.subscribe(result => {
      if (result) { }
    });
  }

  pageEvents(event: any) {
    this.router.navigate(['./student'], { queryParams: { page: event.pageIndex, size: event.pageSize, direcion: this.sortDirection } });
  }
  sortData(event) {
    this.sortDirection = event.direcion;
  }

  onDeleteClicked(elementId) {
    const dialogRef = this.appConfirmService.confirm({
      title: `Delete student`,
      message: `Are you sure you want to delete student?`,
      showTextField: false,
      placeholderTextField: '',
      showSelectField: false, // Remove the dropdown
      placeholderSelectField: '',
      optionsSelectField: [] // No options needed
    });
  
    dialogRef.subscribe(result => {
      if (result) {
        let payload = {
          id: elementId,
          deleteType: "softDelete",
          deletionReasonId: null // No reason needed
        };
        // Call for delete participant
        this.participantAscService.deleteUser(JSON.stringify(payload)).subscribe((res: any) => {
          let resp = JSON.parse(res);
          this.snackBarService.success(resp.applicationMessage);
          this.paginator.firstPage();
          this.resolveUsers(this.filterBy);
        }, err => {
          console.log(err);
          this.snackBarService.success(err.error.applicationMessage);
        });
      }
    });
  }
  

}
