import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from 'src/app/framework/constants/url-constants';

@Injectable({
  providedIn: 'root'
})
export class ParticipantAscService {

  constructor(private readonly http: HttpClient) { }


  findAllPaginated(sort: string, page: number, size: number, body: any): Observable<any> {
    const href = `${BaseUrl.USER}/user/search`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  getFilteredSUList(sort: string, size: number, page: number, body: any): Observable<any> {
    const href = `${BaseUrl.USER}/user/filterUserList`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString())
        .set('sort', sort.toString())
    });
  }

  getServiceUsers(sort: string, size: number, page: number, body: any): Observable<any> {
    const href = `${BaseUrl.USER}/user/search`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString())
        .set('sort', sort.toString())
    });
  }

  saveNewParicipantasc(payload) {
    const href = `${BaseUrl.USER}/serviceUser/create`;
    return this.http.post<any>(href, payload);
  }

  editParticipantasc(payload) {
    const href = `${BaseUrl.USER}/serviceUser/update`;
    return this.http.put<any>(href, payload);
  }
  getParicipantascDetails(id: number) {
    const href = `${BaseUrl.USER}/serviceUser/get/${id}`;
    return this.http.get<any>(href);
  }

  getValidLicences(userType: number) {
    const href = `${BaseUrl.USER}/user-detail/check-licences/${userType}`;
    return this.http.get<any>(href);
  }

  getUserDetails(id) {
    const href = `${BaseUrl.USER}/captrlearner/${id}`;
    return this.http.get<any>(href);
  }

  resolveLoggedInUserRole() {
    return JSON.parse(atob(localStorage.getItem('token').split('.')[1])).roleId;
  }

  getUserRefDataDetails() {
    const href = `${BaseUrl.USER}/learner/refData`;
    return this.http.get<any>(href);
  }

  getProfileDetails(id): Observable<any> {
    const href = `${BaseUrl.USER}/serviceUser/get/${id}`;
    return this.http.get<any>(href);
  }

  resetPassword(id) {
    const href = `${BaseUrl.USER}/su/reset-password/${id}`;
    return this.http.post<any>(href, {});
  }

  unlockAccount(id: number) {
    const href = `${BaseUrl.USER}/SUAccountUnlock/${id}`;
    return this.http.post<any>(href, {});
  }

  deleteUser(payload) {
    const href = `${BaseUrl.USER}/user/delete-User`;
    return this.http.post(href, payload, { responseType: 'text' });
  }

}
