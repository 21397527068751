<mat-card id='view-resorce-setup'>

    <mat-card-header>
        <span class="search-container example-form">
            <mat-form-field id="search_service_user_form" appearance="fill" class="width">
                <mat-label>Search by course</mat-label>
                <input id="search_service_user_input" autocomplete="off"
                    (keydown.enter)="onFilter(search_service_user_input.value)" matInput
                    #search_service_user_input aria-label="Search a user">
                <button id="do_search_button" mat-button matSuffix mat-icon-button aria-label="Search"
                    (click)="onFilter(search_service_user_input.value)">
                    <mat-icon>search</mat-icon>
                </button>
                <button id="clear_search_button" *ngIf="search_service_user_input.value !==''" mat-button matSuffix
                    mat-icon-button aria-label="Clear" (click)="onFilter(''); search_service_user_input.value=''">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </span>
    </mat-card-header>

    <mat-card-content class="course-setup">
        <mat-table id="coursesetup_table" #table class="isMobile" [dataSource]="dataSource">
            <ng-container matColumnDef="course">
                <mat-header-cell *matHeaderCellDef id="course">
                    Moodle courses
                </mat-header-cell>
                <mat-cell *matCellDef="let element"><span class="mobile-label">Moodle courses: </span>
                    {{ element.fullname }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="enrolledCount">
                <mat-header-cell *matHeaderCellDef id="enrolledCount">
                    {{currentlyEnrolled}}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"><span class="mobile-label">Participants currently enrolled: </span>
                    {{ element.participantscount || '0'}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button *auth="[86,2]" id="view_course_button_{{element.fullname}}" mat-icon-button color="primary"
                    (click)="courseLaunch(element.id,'')"  title="View" attr.aria-label="View">
                        <mat-icon>visibility</mat-icon>
                    </button>

                    <button mat-icon-button [matMenuTriggerFor]="menu" id="more_action_{{element.id}}"
                        matTooltip="More Actions" [matTooltipShowDelay]="800" class="desktop">
                        <mat-icon color="primary">menu</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu" class="desktop">
                        <button *auth="[86,1]" mat-menu-item id="enrol_course_button_{{element.id}}" (click)="enrolLearner(element.id)">
                            <mat-icon color="primary">person</mat-icon>
                            <mat-icon color="primary" class="unenroll">add</mat-icon>
                            <span>{{ enrollmentLabel }}</span>
                        </button>

                        <button *auth="[86,3]" mat-menu-item id="unroll_course_button_{{element.id}}" (click)="unenrolLearner(element.id)">
                            <mat-icon color="primary">person</mat-icon>
                            <mat-icon color="primary" class="unenroll">remove</mat-icon>
                            <span>{{ unerollmentLabel }}</span>
                        </button>
                    </mat-menu>

                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator id="user_pagination" [pageSize]="pageSize" [showFirstLastButtons]='true' [hidePageSize]="true">
        </mat-paginator>
    </mat-card-content>
</mat-card>